<template>
  <div class="log">日志管理</div>
</template>

<script>
export default {
  name: "log"
}
</script>

<style lang="scss">
.log{

}
</style>